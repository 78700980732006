<template>
  <skin-dysfunctions-template
    :dysfunctions="skinDysfunctions"
    :doctor-full-name="doctorFullName"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import SkinDysfunctionsTemplate from '@/modules/questionnaire/components/steps/common/skin-dysfunctions/skin-dysfunctions/SkinDysfunctionsTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { types } from '@/modules/questionnaire/store/types';

import { SKIN_DYSFUNCTIONS } from '@/modules/questionnaire/api/constants';

const { field } = fieldBuilder;

const FIELDS_DYSFUNCTION = [
  'skinAppearanceDiscomfort',
  'inflammationDiscomfort',
  'drynessDiscomfort',
  'greasySkinDiscomfort',
  'textureDiscomfort',
  'acneDiscomfort',
  'hyperpigmentationDiscomfort'
];
const FIELDS = [...FIELDS_DYSFUNCTION.map(field)];

export default {
  name: 'SkinDysfunctions',
  components: {
    SkinDysfunctionsTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    ...mapGetters({
      doctorFullName: types.getters.GET_DOCTOR_FULL_NAME,
      isSkinAppearanceDiscomfort: types.getters.GET_IS_SKIN_APPEARANCE_DISCOMFORT,
      isSkinRednessDiscomfort: types.getters.GET_IS_SKIN_REDNESS_DISCOMFORT,
      isSkinDrynessDiscomfort: types.getters.GET_IS_SKIN_DRYNESS_DISCOMFORT,
      isSkinOilinessDiscomfort: types.getters.GET_IS_SKIN_OILINESS_DISCOMFORT,
      isSkinTextureDiscomfort: types.getters.GET_IS_SKIN_TEXTURE_DISCOMFORT,
      isPimplesDiscomfort: types.getters.GET_IS_PIMPLES_DISCOMFORT,
      isSkinPigmentationDiscomfort: types.getters.GET_IS_SKIN_PIGMENTATION_DISCOMFORT
    }),
    skinDysfunctionsBinding() {
      return [
        {
          isExist: this.isSkinAppearanceDiscomfort,
          value: this.skinAppearanceDiscomfort,
          fieldName: 'skinAppearanceDiscomfort',
          name: SKIN_DYSFUNCTIONS.SKIN_APPEARANCE
        },
        {
          isExist: this.isSkinRednessDiscomfort,
          value: this.inflammationDiscomfort,
          fieldName: 'inflammationDiscomfort',
          name: SKIN_DYSFUNCTIONS.SKIN_REDNESS
        },
        {
          isExist: this.isSkinDrynessDiscomfort,
          value: this.drynessDiscomfort,
          fieldName: 'drynessDiscomfort',
          name: SKIN_DYSFUNCTIONS.SKIN_DRYNESS
        },
        {
          isExist: this.isSkinOilinessDiscomfort,
          value: this.greasySkinDiscomfort,
          fieldName: 'greasySkinDiscomfort',
          name: SKIN_DYSFUNCTIONS.SKIN_OILINESS
        },
        {
          isExist: this.isSkinTextureDiscomfort,
          value: this.textureDiscomfort,
          fieldName: 'textureDiscomfort',
          name: SKIN_DYSFUNCTIONS.SKIN_TEXTURE
        },
        {
          isExist: this.isPimplesDiscomfort,
          value: this.acneDiscomfort,
          fieldName: 'acneDiscomfort',
          name: SKIN_DYSFUNCTIONS.PIMPLES
        },
        {
          isExist: this.isSkinPigmentationDiscomfort,
          value: this.hyperpigmentationDiscomfort,
          fieldName: 'hyperpigmentationDiscomfort',
          name: SKIN_DYSFUNCTIONS.SKIN_PIGMENTATION
        }
      ];
    },
    skinDysfunctions() {
      return this.skinDysfunctionsBinding.reduce((acc, { isExist, value, fieldName }) => {
        if (isExist) {
          acc[fieldName] = value;
        }

        return acc;
      }, {});
    }
  },
  created() {
    this.initSkinDysfunctionsBinding();
  },
  methods: {
    initSkinDysfunctionsBinding() {
      this.skinDysfunctionsBinding.forEach(({ isExist, value, fieldName }) => {
        if (isExist) {
          this[fieldName] = value || 'low';
        }
      });
    }
  }
};
</script>
